
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  SetupContext,
} from 'vue';
import resource from '@/modules/resource';
import validation from '@/modules/validation';
// import sessionModule from '@/modules/auth/sessionModule';
import { useStore } from 'vuex';

export default defineComponent({
  inheritAttrs: false,
  emits: ['navigateToNextOfKinInfo', 'emitSuccess'],
  setup: (_props, { emit }: SetupContext) => {
    const { basicForm, getFormData } = resource();
    const { validateForm } = validation();
    // const { mapProfileData } = sessionModule();

    const { state } = useStore();

    const data = reactive({
      form: basicForm([
        {
          name: 'bvn',
          rules: 'required|number',
        },
        {
          name: 'gender',
          rules: 'required',
        },
        {
          name: 'dob',
          rules: 'required',
        },
        {
          name: 'address',
          rules: 'required',
        },

        {
          name: 'bank_name',
          rules: 'required',
        },
        {
          name: 'account_number',
          rules: 'required|number',
        },
      ]),
    });

    const bankOptions = computed(() =>
      state?.utils?.banks?.map((bank: any) => {
        return {
          title: bank?.name,
          value: bank?.code,
        };
      }),
    );

    const genderOptions = computed(() => {
      return [
        { title: 'Male', value: 'male' },
        { title: 'Female', value: 'female' },
      ];
    });

    onMounted(() => {
      setTimeout(() => {
        for (const key in data.form.data) {
          data.form.data[key].value =
            state?.user?.profile?.[key] ||
            state?.user?.bank_accounts?.[0][key] ||
            '';
        }
      }, 500);
    });

    const getBankName = (bankCode: string): string => {
      return state?.utils?.banks?.find((bank: any) => bank?.code === bankCode)
        ?.name;
    };

    const proceed = () => {
      if (!validateForm(data.form)) return false;
      emit('navigateToNextOfKinInfo', getFormData(data.form));
    };

    return {
      ...toRefs(data),
      bankOptions,
      genderOptions,
      proceed,
      getBankName,
    };
  },
});
