<template>
  <div class="text-2xl font-bold text-invest-primary">Personal Information</div>
  <p class="mt-3 mb-8 text-xs text-invest-dark">
    Tell us a little more about yourself.
  </p>
  <form-input name="bvn" :form="form" v-model="form.data.bvn.value">
    BVN
  </form-input>
  <div class="grid grid-cols-2 gap-3">
    <div class="col-span-1">
      <form-input
        type="select"
        :form="form"
        name="gender"
        :options="genderOptions"
        :defaultValue="`${form.data.gender.value || ''}`"
        v-model="form.data.gender.value"
      >
        Select Gender
      </form-input>
    </div>
    <div class="col-span-1">
      <form-input
        type="date"
        name="dob"
        :form="form"
        v-model="form.data.dob.value"
      >
        Date of birth
      </form-input>
    </div>
  </div>
  <form-input name="address" :form="form" v-model="form.data.address.value">
    Home address
  </form-input>
  <h5 class="text-xs font-bold text-invest-dark mt-6 mb-5">Bank information</h5>
  <form-input
    type="select"
    :form="form"
    name="bank_name"
    :options="bankOptions"
    :defaultValue="`${getBankName(form.data.bank_name.value) || ''}`"
    v-model="form.data.bank_name.value"
  >
    Bank
  </form-input>
  <form-input
    name="account_number"
    :form="form"
    v-model="form.data.account_number.value"
  >
    Account number
  </form-input>
  <div class="mt-8">
    <button type="submit" class="btn btn-primary" @click="proceed">
      Continue
    </button>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  SetupContext,
} from 'vue';
import resource from '@/modules/resource';
import validation from '@/modules/validation';
// import sessionModule from '@/modules/auth/sessionModule';
import { useStore } from 'vuex';

export default defineComponent({
  inheritAttrs: false,
  emits: ['navigateToNextOfKinInfo', 'emitSuccess'],
  setup: (_props, { emit }: SetupContext) => {
    const { basicForm, getFormData } = resource();
    const { validateForm } = validation();
    // const { mapProfileData } = sessionModule();

    const { state } = useStore();

    const data = reactive({
      form: basicForm([
        {
          name: 'bvn',
          rules: 'required|number',
        },
        {
          name: 'gender',
          rules: 'required',
        },
        {
          name: 'dob',
          rules: 'required',
        },
        {
          name: 'address',
          rules: 'required',
        },

        {
          name: 'bank_name',
          rules: 'required',
        },
        {
          name: 'account_number',
          rules: 'required|number',
        },
      ]),
    });

    const bankOptions = computed(() =>
      state?.utils?.banks?.map((bank: any) => {
        return {
          title: bank?.name,
          value: bank?.code,
        };
      }),
    );

    const genderOptions = computed(() => {
      return [
        { title: 'Male', value: 'male' },
        { title: 'Female', value: 'female' },
      ];
    });

    onMounted(() => {
      setTimeout(() => {
        for (const key in data.form.data) {
          data.form.data[key].value =
            state?.user?.profile?.[key] ||
            state?.user?.bank_accounts?.[0][key] ||
            '';
        }
      }, 500);
    });

    const getBankName = (bankCode: string): string => {
      return state?.utils?.banks?.find((bank: any) => bank?.code === bankCode)
        ?.name;
    };

    const proceed = () => {
      if (!validateForm(data.form)) return false;
      emit('navigateToNextOfKinInfo', getFormData(data.form));
    };

    return {
      ...toRefs(data),
      bankOptions,
      genderOptions,
      proceed,
      getBankName,
    };
  },
});
</script>
